@import '../../styles/vars';

.Timeline {
  &_PageSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_PeriodSpinner {
    margin: 20px auto;
  }

  &_Page {
    padding-bottom: 30px;
  }

  &_FutureContainer {
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &_Section {
    margin-bottom: 20px;
  }

  &_SectionHeader {
    padding: 20px;
    padding-bottom: 0;

    h1 {
      margin-bottom: 10px;
    }
  }

  &_BalanceLink {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .Icon {
      flex: 0 0 auto;
      margin-right: -.55rem;
    }
  }

  &_Balances {
    flex: 1 1 auto;
    grid-template-columns: 1fr auto;
    grid-row-gap: 5px;

    dd {
      text-align: right;
    }
  }

  &_LoadMore {
    display: block;
    width: 100%;
    background: none;
    text-align: center;
    padding: 0;
  }
}