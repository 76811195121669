@import '../../../styles/vars';

.RadioToggleInput {
  display: inline-flex;
  height: 2rem;
  width: 6rem;
  box-sizing: content-box;
  padding: 0.25rem;
  border-radius: 4px;
  background-color: map-get($color, 'white.400');

  &_Container {
    margin-bottom: 20px;
  }

  &_Label {
    display: block;
    width: 100%;
    appearance: none;
    margin-bottom: 4px;
  }

  &_On {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 2rem;
    border-radius: 4px;
    background-color: map-get($color, 'white.400');
  }

  &_Off {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 2rem;
    border-radius: 4px;
    background-color: map-get($color, 'red.100');
    color: map-get($color, 'white');
  }

  &.-checked {
    .RadioToggleInput_On {
      background-color: map-get($color, 'green.100');
      color: map-get($color, 'white');
    }
    .RadioToggleInput_Off {
      background-color: map-get($color, 'white.400');
      color: inherit;
    }
  }

  &.-disabled {
    opacity: .5;
  }
}