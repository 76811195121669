@import './styles/vars';

.App {
  min-height: 100vh;
  background-color: map-get($color, 'white.200');

  &.-loading {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_PageSpinner {
    
  }

  &_Scroller {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding-bottom: 90px;
    min-height: calc(100vh - #{$header-height + $nav-height});
  }

  &_Header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    padding: 0 1rem;
    position: relative;
  }

    &_UserMenuLink {
      display: block;
      width: 2rem;
      height: 2rem;
      flex: 0 0 auto;
      overflow: hidden;
      border-radius: 50%;
      padding: 0;
      background: none;

      .Icon {
        width: 100%;
        height: 100%;
      }
    }

  &_Main {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &_Nav {
    display: flex;
    background-color: map-get($color, 'white');
    position: relative;
    border: 1px solid map-get($color, 'grey.300');
    box-shadow: 0 0px 12px 4px rgba(0,0,0,.15);
    position: fixed;
    bottom: 24px;
    left: 10px;
    right: 10px;
    border-radius: 100px;
    padding: 0 15px;

    a {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 33.33%;
      height: $nav-height;
      padding: 0 5px;
      text-align: center;
      border-right: 1px solid map-get($color, 'grey.300');
      font-size: .8rem;

      &:last-child {
        border-right: none;
      }
    }

    .Icon {
      display: block;
      margin: 0 auto 5px auto;
    }
  }

  &_HeaderAccountControls {
    flex: 0 1 auto;
    min-width: 0;
    max-width: 15em;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0;
    background-color: transparent;
    
    h5 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: .5em;
    }
  }
  &_ActiveAccount {
    height: 1.5rem;
    width: auto;
    margin-right: 1em;
  }

  &_HeaderMenu {
    background-color: map-get($color, 'white');
    position: absolute;
    top: 60px;
    left: 20px;
    padding: 0;
    z-index: 1;

    &.-right {
      left: auto;
      right: 20px;
    }

    .Button,
    a {
      font-weight: bold;
      display: flex;
      align-items: center;
      min-width: 170px;
      max-width: 220px;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      font-size: .8;
      text-align: left;
      
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    img {
      height: 1.5rem;
      width: auto;
      margin-right: 1em;
    }
  }


  &_NoAuthState {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
      margin-bottom: 20px;
    }
  }
}