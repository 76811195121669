@import '../../styles/vars';

.BlockListTransaction {
  &_Title {
    display: block;
    font-size: .8em;
    margin-bottom: 5px;
    word-break: break-word;
  }
  &_Subtitle {
    display: block;
    font-size: .8em;
    color: map-get($color, 'grey.10');
  }
  &_Amount {
    font-weight: bold;
  }
}