@import '../../../styles/vars';

.Budget {
  &_Columns {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &_Column {
    width: 50%;
    flex: 0 0 auto;

    .ProgressDial {
      margin: 0 auto;
      --size: 7rem;
      --border-size: 10px;

      &_Label {
        font-size: map-get($font-size, 'lg');
      }
    }
  }

  &_Data {
    display: flex;
    flex-flow: row wrap;
  }

  &_DataItem {
    flex: 0 0 auto;
    width: 50%;
    padding: 10px;

    &:last-child {
      width: 100%;
    }
  }
  
  &_Remaining {
    font-weight: bold;
    font-size: map-get($font-size, 'xl');
  }
}