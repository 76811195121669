@import '../../../styles/vars';

.TransactionAmount {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  &_Field {
    flex: 1 1 auto;
    padding-right: 1rem;
  }

  &_Toggle {
    flex: 0 0 auto;
  }

  &_Label {
    display: block;
    margin-bottom: 4px;
  }
}
