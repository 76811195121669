.PendingTransaction {
  &_DateField {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .DateInput {
      flex: 1 1 auto;
      min-width: 0;
      padding-right: 1rem;
    }
    .ToggleInput {
      flex: 0 0 auto;
      width: 6rem;
    }
  }

  &_Form {
    margin-bottom: 4rem;
  }
}