@import '../../../styles/vars';

.BalanceBreakdown {
  &_PeriodRange {
    margin: 20px 0;
  }

  &_Section {
    border-left: 10px solid map-get($color, 'red.100');

    &.-pending {
      border-color: map-get($color, 'blue.700');
    }
    &.-budgets {
      border-color: map-get($color, 'green.100');
    }
  }

  &_BudgetCategory {
    .-disabled & {
      color: map-get($color, 'grey.400');
    }
  }
}