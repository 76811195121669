.SlideRoute {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  &_Header {
    padding: 20px 10px;
    flex: 0 0 auto;
  }

  &_Scroller {
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &_Back {
    font-weight: bold;
  }
}