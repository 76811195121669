@import '../../styles/vars';

.BlockListSaving {
  // &_Progress.ProgressDial{
  //   --size: 3rem;
  //   --border-size: 6px;
  // }

  .BlockListItem_Main {
    display: flex;
    align-items: center;
  }

  &_Title {
    flex: 1 1 auto;
  }

  &_Stats {
    flex: 0 0 auto;
    text-align: right;
  }

  &_Stat {
    display: block;
    font-size: map-get($font-size, 'sm');

    &:first-child {
      font-weight: bold;
      font-size: map-get($font-size, 'md');
    }
  }
}