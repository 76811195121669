@import '../../../styles/vars';

.CheckboxToggleInput {
  display: inline-flex;
  height: 2rem;
  width: 6rem;
  box-sizing: content-box;
  padding: 0.25rem;
  border-radius: 4px;
  background-color: map-get($color, 'white.400');
  justify-content: flex-end;

  &_Toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 2rem;
    border-radius: 4px;
    background-color: map-get($color, 'red.100');
    color: map-get($color, 'white');
  }

  &.-checked {
    justify-content: flex-start;

    .CheckboxToggleInput_Toggle {
      background-color: map-get($color, 'green.100');
    }
  }
  &.-disabled {
    opacity: .5;
  }
}