.SelectInput {
  display: block;
  margin-bottom: 20px;
  position: relative;

  &_Label {
    display: block;
    margin-bottom: 4px;
  }

  .Icon {
    position: absolute;
    right: .5em;
    bottom: .5em;
    pointer-events: none;
  }
}
