body,
input,
select,
button,
textarea {
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.25;
  color: map-get($color, 'grey.10');
}

a {
  text-decoration: inherit;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: map-get($font-size, 'xxl');
}

h2 {
  font-size: map-get($font-size, 'xl');
}

h3 {
  font-size: map-get($font-size, 'lg');
}

dl {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

small {
  font-size: 80%;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
select,
textarea {
  display: block;
  appearance: none;
  width: 100%;
  min-width: 0;
  border: none;
  outline: none;
  background-color: map-get($color, 'grey.200');
  padding: 10px;
  border-radius: 0;

  &:focus {
    outline: 2px solid map-get($color, 'grey.100');
  }
  &:disabled,
  &[disabled] {
    color: map-get($color, 'grey.10');
  }
}

textarea {
  min-width: 100%;
  max-width: 100%;
  resize: vertical;
}

button,
input[type="submit"] {
  padding: 10px 20px;
  background-color: map-get($color, 'grey.200');
  border-radius: 0;
  border: none;
  font-weight: bold;
}
