.Accounts {
  &_Page {
    padding-top: 20px;
  }

  &_Account.BlockListItem {
    h4 {
      margin-bottom: 5px;
    }
    .BlockListItem_Left {
      img {
        width: 2rem;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  &_ConnectAccount {
    .Icon {
      width: 1em;
      height: 1em;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
}