$font-body: 'Roboto', Sans-serif;

$color: (
  'white': #fff,
  'white.100': #f7f7f7,
  'white.200': #f1f1f1,
  'white.400': #e3e3e3,
  'grey.10': #333,
  'grey.100': #CECECE,
  'grey.200': #ECECEC,
  'grey.300': #CCCCCC,
  'grey.400': #C5C5C5,
  'grey.500': #999,
  'blue.100': #D6DFE7,
  'blue.200': #c2d0db,
  'blue.300': #EAF4FC,
  'blue.700': #4F8FC2,
  'blue.750': #4181b5,
  'green.100': #61c16b,
  'red.100': #ff6347,
  'orange.100': #ff872a
);

$font-size: (
  'xxl': 2rem,
  'xl': 1.5rem,
  'lg': 1.25rem,
  'md': 1rem,
  'sm': .8rem,
);

$header-height: 76px;
$nav-height: 75px;
