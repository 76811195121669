@import '../../styles/vars';

.Balance {
  

  &_PageSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_Page {
    padding-top: 20px;
  }

  &_PageBox.Box {
    padding-top: 0;
  }

  &_Section {
    margin-bottom: 20px;
  }

  &_SectionList {
    
  }
}