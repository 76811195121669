@import '../../styles/vars';

.Toggle {
  display: inline-flex;
  height: 2rem;
  width: 4rem;
  box-sizing: content-box;
  padding: 0.25rem;
  border-radius: 6rem;
  background-color: map-get($color, 'white.400');

  &:after {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: map-get($color, 'grey.100');
  }

  &.-checked {
    &:after {
      transform: translateX(100%);
      background-color: map-get($color, 'green.100');
    }
  }
  &.-disabled {
    opacity: .5;
  }

  &.-small {
    height: 1rem;
    width: 2rem;

    &:after {
      width: 1rem;
      height: 1rem;
    }
  }
}