.SavingTransaction {
  &_Form {
    .Input {
      display: none;
    }
  }

  &_DateField {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-align: center;

    .DateInput {
      display: none;
    }
    .ToggleInput {
      flex: 0 0 auto;
      width: 6rem;
    }
  }

  &_Form {
    margin-bottom: 4rem;
  }
}