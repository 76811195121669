.PeriodRange {
  display: flex;
  flex-flow: row wrap;
  text-align: center;

  &_Icon {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 10px;
  }

  &_Arrow {
    flex: 0 0 auto;
    width: 1rem;
  }
  
  &_From,
  &_To {
    flex: 1 1 auto;
    min-width: 0;
  }
}