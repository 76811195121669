.Account {
  &_PageLoading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &_Header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-bottom: 0;
  }

  &_ProviderLogo {
    width: 3rem;
    height: auto;
    margin-right: 20px;
  }

  &_AccountDetails {
    grid-template-columns: 1fr;

    dt {
      font-weight: bold;
      padding-right: 10px;
    }
    dd {
      margin-bottom: 10px;
    }
  }
}