@import '../../styles/vars';

.Button {
  display: inline-block;
  padding: 10px;
  background-color: map-get($color, 'blue.700');
  color: map-get($color, 'white');
  border: none;
  outline: none;
  text-decoration: none;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;

  &:active {
    background-color: map-get($color, 'blue.750');
  }

  &.-block {
    display: block;
    width: 100%;
  }

  &.-link {
    background: none;
    color: inherit;
    font-weight: bold;

    &:active {
      background: none;
    }
  }

  &_Spinner {
    margin: 0 auto;
    width: 1.25rem;
    height: 1.25rem;
    border-width: 3px;
  }
}