.RecurringInput {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  margin-left: -10px;
  margin-right: -10px;

  &_Label {
    display: block;
    margin-bottom: 4px;
    padding: 0 10px;
  }

  &_Field {
    display: block;
    width: 75%;
    padding: 0 10px;

    .RecurringInput.-with-part-2 & {
      width: 33.333%;
    }
  }
}
