@import '../../../styles/vars';

.TransferAmount.TransactionAmount {
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;

  .TransactionAmount_Field {
    width: 100%;
    flex: 0 0 auto;
    max-width: 200px;
    padding: 0;
    margin-bottom: 20px;
  }
  .TransactionAmount_Toggle.CheckboxToggleInput {
    width: 100%;

    .CheckboxToggleInput_Toggle {
      background-color: map-get($color, 'green.100');
    }

    &.-checked .CheckboxToggleInput_Toggle {
      background-color: map-get($color, 'red.100');
    }
  }
}