.ConnectBank {
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;

  &_Title {
    margin-bottom: 20px;
  }
}