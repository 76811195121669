@import '../../styles/vars';

.Spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid map-get($color, 'blue.750');
  border-radius: 50%;
  animation: Spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: map-get($color, 'blue.750') transparent transparent transparent;
}
.Spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.Spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.Spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

.SimpleSpinner {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 0.25em solid rgba(0,0,0,.3);
  border-bottom-color: transparent;
  animation: Spinner .4s infinite linear;
}

@keyframes Spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
