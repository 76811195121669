@import '../../styles/vars';

.BlockListItem {
  appearance: none;
  padding: 0;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  border-radius: 0;
  font-weight: inherit;
  display: block;
  width: 100%;
  text-align: inherit;

  &:nth-child(odd) {
    background-color: map-get($color, 'white.100');
  }

  &_Inner {
    display: flex;
    align-items: center;
    padding: 20px;

    .BlockListItem.-small & {
      padding-top: 0;
      padding-bottom: 0;
      height: 2.25rem;
    }
  }

  &_LeftIcon,
  &_Left {
    padding-right: 10px;
    flex: 0 0 auto;
  }
  &_RightIcon,
  &_Right,
  &_Chevron {
    padding-left: 10px;
    flex: 0 0 auto;
  }

  &_Main {
    flex: 1 1 auto;
    min-width: 0;
  }

  &.-title {
    background-color: map-get($color, 'blue.300');
    font-size: .8em;
    font-weight: bold;
  }

  &.-feature {
    background-color: map-get($color, 'grey.200');
    border-bottom: 2px solid rgba(0,0,0,.1);
  }
}