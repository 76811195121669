@import '../../styles/vars';

.ProgressDial {
  --size: 3rem;
  --border-size: 6px;

  width: var(--size);
  height: var(--size);
  position: relative;

  &_Pie {
    width: 100%;
    height: 100%;
    clip: rect(0, var(--size), var(--size), calc(var(--size) / 2));
    position: absolute;
    left: 0;
    top: 0;
  }

  &_HalfCircle {
    width: 100%;
    height: 100%;
    border: var(--border-size) solid map-get($color, 'blue.700');
    border-radius: 50%;
    clip: rect(0, calc(var(--size) / 2), var(--size), 0);
    position: absolute;
    left: 0;
    top: 0;
  }

  &_Label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75em;
    border: var(--border-size) solid map-get($color, 'grey.100');
    font-weight: bold;
  }
}
