@import '../../styles/vars';

@keyframes SlideTransitionRouter_AppearanceFix {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: hidden;
  }
}

.SlideTransitionRouter {
  &_Router {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: map-get($color, 'white');
    transition: transform .3s ease-out;
    animation: SlideTransitionRouter_AppearanceFix .3s;

    &.-slide-enter,
    &.-slide-exit {
      transform: translateX(100%);
    }
    &.-slide-exit {
      transition-timing-function: ease-in;
    }
  }
}
