@import '../../styles/vars';

.DrawerModal {
  display: flex;
  flex-flow: column nowrap;
  background-color: map-get($color, 'white');
  position: fixed;
  z-index: 1;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform .2s ease-out;

  &.-slide-enter,
  &.-slide-exit {
    transform: translateY(100%);
  }
  &.-slide-exit {
    transition-timing-function: ease-in;
  }

  &_Overlay {
    background-color: rgba(0,0,0,.75);
    transition: all .1s ease-out;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.-fade-enter,
    &.-fade-exit {
      opacity: 0;
      visibility: hidden;
    }
  }

  &_Header {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    align-items: center;
  }

  &_Title {
    flex: 1 1 auto;
  }

  &_Close {
    flex: 0 0 auto;
    padding: 0;
    font-size: 2rem;
  }

  &_Content {
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}