@import '../../styles/vars';

.Card {
  background-color: map-get($color, 'white');
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,.08);
  border: 1px solid map-get($color, 'grey.300');
  
  &_Header {
    padding: 20px;
    text-align: center;
  }
  &_Footer {
    padding: 20px;
  }

  &_Heading {
    text-align: center;

    margin: 30px 0 20px 0;

    .Card_Header + & {
      margin-top: 0;
    }
  }

  &.-full {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}