@import '../../styles/vars';

.AvailableBalance {
  display: block;
  padding: 20px;

  &_Header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    h1 {
      flex: 1 1 auto;
      line-height: 1;
      padding-right: 10px;
    }
    p {
      flex: 0 0 auto;
      text-align: right;
    }
  }

  &_Balances {
    flex: 1 1 auto;
    grid-template-columns: 1fr auto;
    grid-row-gap: 5px;
    align-items: end;

    dd {
      text-align: right;
    }
  }

  &_Balance {
    font-size: map-get($font-size, 'xl');

    .Icon {
      margin-right: -.55rem;
    }
  }
}